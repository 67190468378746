import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import axios from 'axios';
import {withRouter} from "react-router-dom";

import Dropdown from '../../../../../components/dropdown/dropdown';
import {createUrl} from "../../../../../util/formatters";
import {openModal as _openModal} from '../../../../../redux/modal/modal-actions';
import giftAdded from './alert-templates/gifts/gift-added';
import giftEdited from './alert-templates/gifts/gift-edited';
import giftEditedWasClaimed from './alert-templates/gifts/gift-edited-was-claimed';
import giftDeleted from './alert-templates/gifts/gift-deleted';
import giftDeletedWasClaimed from './alert-templates/gifts/gift-deleted-was-claimed';
import giftCompleted from './alert-templates/gifts/gift-completed';
import giftRestored from './alert-templates/gifts/gift-restored';
import initiateClaimRectification from './alert-templates/gifts/gift-claim-rectification';
import giftClaimExpiration from './alert-templates/gifts/gift-claim-expiration';
import giftClaimExpired from './alert-templates/gifts/gift-claim-expired';
import giftClaimed from './alert-templates/gifts/gift-claimed';
import giftUnclaimed from './alert-templates/gifts/gift-unclaimed';
import contributorInvite from './alert-templates/contributors/contributorInvite';
import contributorAdded from './alert-templates/contributors/contributorAdded';
import contributorRemoved from './alert-templates/contributors/contributorRemoved';
import contributorsFinalized from './alert-templates/contributors/contributorsFinalized';
import contributorsReopened from './alert-templates/contributors/contributorsReopened';
import hubDeleted from './alert-templates/hubs/hub-deleted';
import hubNewOwner from './alert-templates/hubs/hub-new-owner'
import hubRoleModified from './alert-templates/hubs/hub-role-modified'
import hubMemberAdded from './alert-templates/hubs/hub-member-added'
import hubMemberRemoved from './alert-templates/hubs/hub-member-removed'
import hubRequestSent from './alert-templates/hubs/hub-request-sent';
import hubRequestAnswered from './alert-templates/hubs/hub-request-answered';
import userPasswordChanged from './alert-templates/user/user-password-changed';
import userEmailChanged from './alert-templates/user/user-email-changed';
import userEmailAndPasswordChanged from './alert-templates/user/user-email-and-password-changed';
import userWelcome from './alert-templates/user/user-welcome';
import messageReceived from './alert-templates/threads/message-received';
import generalAnnouncement from './alert-templates/general/general-announcement';

import './alerts.css';

export class Alerts extends React.Component {
    static propTypes = {
        openModal: PropTypes.func.isRequired,
        userIsAdmin: PropTypes.bool.isRequired
    };

    Badge = () => {
        let {unreadCount} = this.state;
        if (unreadCount === 0) return <div className="alerts-inactiveBadge" />;
        return (
            <div className="alerts-badge">
                <div className="alerts-activeBadge" />
                <span className="alerts-badgeCount">
                    {unreadCount}
                </span>
            </div>
        )
    };

    static alertTypes = {
        giftAdded,
        giftEdited,
        giftEditedWasClaimed,
        giftDeleted,
        giftDeletedWasClaimed,
        initiateClaimRectification,
        giftClaimExpiration,
        giftClaimExpired,
        giftClaimed,
        giftUnclaimed,
        giftCompleted,
        giftRestored,
        contributorInvite,
        contributorAdded,
        contributorRemoved,
        contributorsFinalized,
        contributorsReopened,
        hubDeleted,
        hubNewOwner,
        hubRoleModified,
        hubMemberAdded,
        hubMemberRemoved,
        hubRequestSent,
        hubRequestAnswered,
        userWelcome,
        userPasswordChanged,
        userEmailChanged,
        userEmailAndPasswordChanged,
        messageReceived,
        generalAnnouncement
    };

    onShowMoreNotifications = async () => {
        let {alerts, alertsShowing} = this.state;
        let alertsRequested = alertsShowing + 5;
        let moreAlerts;
        if (alerts.length < alertsRequested) {
            let oldestCreatedAt = alerts[alerts.length - 1].createdAt;
            moreAlerts = (await axios.get(createUrl(`/alerts?limit=5&expandEvent=true&createdBefore=${oldestCreatedAt}`))).data;
        }
        this.setState(state => {
            let newState = {alertsShowing: alertsRequested};
            if (moreAlerts) {
                newState.alerts = [
                    ...state.alerts,
                    ...moreAlerts
                ]
            }
            return newState;
        });
    };

    ShowMoreNotifications = () => {
        let {totalCount, alertsShowing} = this.state;
        if (alertsShowing >= totalCount) return null;
        return (
                <button className="alerts-moreButton" onClick={this.onShowMoreNotifications}>
                    Show More
                </button>
        )
    };

    AlertHeader = () => {
        let {alerts} = this.state;
        let someUnread = alerts.some(alert => alert.unread);
        let markAllReadButton = someUnread && (
            <button className="alerts-markReadButton" onClick={this.markAllRead}>
                Mark All Read
            </button>
        );
        let header;
        if (alerts.length) {
            header = <div className="alerts-notificationHeader">Notifications:</div>
        } else {
            header = (
                <div>
                    <div className="alerts-notificationHeader">No Notifications</div>
                </div>
            )
        }
        return (
            <div className="alerts-notificationHeaderBar">
                {header}
                {markAllReadButton}
            </div>
        );
    };

    NotificationList = ({closeMenu}) => {
        let {AlertHeader} = this;
        let {history, activeUserId, openModal, userIsAdmin} = this.props;
        let {alerts, alertsShowing} = this.state;
        useEffect(() => () => {
            if (this.state.alertsShowing !== 5) {
                this.setState({alertsShowing: 5});
            }
        }, []);
        let displayableNotifications = alerts.slice(0, alertsShowing).map(
            (alert, index) => {
                let AlertType = Alerts.alertTypes[alert.event.type];
                if (!AlertType) return null;
                return (
                    <AlertType
                        key={index}
                        alert={alert}
                        closeMenu={closeMenu}
                        toggleNotificationRead={this.toggleNotificationRead(alert)}
                        push={history.push}
                        activeUserId={activeUserId}
                        openModal={openModal}
                        userIsAdmin={userIsAdmin}
                    />
                );
            }
        );
        return (
            <div className="alerts-menu">
                <AlertHeader />
                {displayableNotifications}
            </div>
        );
    };

    markAllRead = async () => {
        await axios.put(createUrl('/alerts/mark-read'));
        this.setState(state => {
            let modifiedAlerts = state.alerts.map(alert => ({
                ...alert,
                unread: false,
                webStatus: 'read'
            }));
            return {
                unreadCount: 0,
                alerts: modifiedAlerts
            };
        });
    };

    toggleNotificationRead = alert => async event => {
        if (event) event.stopPropagation();
        await axios.put(createUrl(alert.links.toggleStatus));
        let alertIndex = this.state.alerts.findIndex(currentAlert => currentAlert.id === alert.id);
        if (alertIndex > -1) {
            this.setState(state => {
                let {alerts, unreadCount} = state;
                let unread = !alert.unread;
                alerts[alertIndex].unread = unread;
                alerts[alertIndex].webStatus = unread ? 'unread' : 'read';
                return {alerts, unreadCount: unread ? unreadCount + 1 : Math.max(unreadCount - 1, 0)};
            })
        }
    };

    checkForAlerts = async () => {
        let {alerts} = this.state;
        let newestAlert = alerts[0] || {};
        let alertsPromise = axios.get(createUrl('/alerts'), {
            params: {
                expandEvent: true,
                createdAfter: newestAlert.createdAt
            }
        });
        let unreadCountPromise = axios.get(createUrl('/alerts/stats'));
        let [newAlerts, {totalCount, unreadCount}] = (await Promise.all([alertsPromise, unreadCountPromise]))
            .map(response => response.data);
        this.setState(state => {
            return {alerts: [...newAlerts, ...state.alerts], totalCount, unreadCount}
        });
        setTimeout(this.checkForAlerts, 20000);
    };

    loadData = async () => {
        let alertsPromise = axios.get(createUrl('/alerts?limit=5&expandEvent=true'));
        let unreadCountPromise = axios.get(createUrl('/alerts/stats'));
        let [alerts, {totalCount, unreadCount}] = (await Promise.all([alertsPromise, unreadCountPromise]))
            .map(response => response.data);
        this.setState({alerts, totalCount, unreadCount});
        setTimeout(this.checkForAlerts, 20000);
    };

    state = {
        alerts: [],
        unreadCount: 0,
        notifications: [],
        alertsShowing: 5
    };

    componentDidMount = async () => {
        await this.loadData();
    };

    render() {
        let {Badge, NotificationList, ShowMoreNotifications} = this;
        return (
            <div className="alerts">
                <Dropdown
                    value={<Badge />}
                    className="alerts-dropdown"
                    buttonClassName="alerts-dropdownButton"
                    menuClassName="alerts-menuWrapper"
                    hideIcon={true}
                    closeOnMenuClick={false}
                >
                    <NotificationList />
                    <ShowMoreNotifications />
                </Dropdown>
            </div>
        );
    }
}

let mapStateToProps = state => ({
    userIsAdmin: state.users.getIn(['user', 'isAdmin'])
});

export default withRouter(connect(mapStateToProps, {openModal: _openModal})(Alerts));
