import React, {useState, useEffect} from 'react';
import {Field, Form} from 'react-final-form';
import qs from 'qs';
import axios from 'axios';

import TitleBar from '../components/title-bar/title-bar';
import {createUrl} from '../../../util/formatters';
import RecipePreview, {RecipePreviewFiller} from '../recipes/recipe-preview/recipe-preview';

import './search.css';

let SearchForm = formProps => {
    let {handleSubmit} = formProps;
    return (
        <form onSubmit={handleSubmit} className="search-form">
            <Field
                name="searchText"
                placeholder="Search"
                component="input"
                type="text"
                className="search-searchBar"
                autoComplete="off"
            />
        </form>
    )
};

let onSubmit = history => async values => {
    let searchText = values?.searchText;
    if (!searchText) return;
    let queryParams = qs.stringify({searchText});
    history.push(`/sous-chef/search?` + queryParams);
};

export default function Search({toggleSidebar, location, history}) {
    let {searchText} = qs.parse(location.search.slice(1));
    let [data, setData] = useState();

    let loadData = async () => {
        if (!searchText) return;
        let recipes = (await axios.get(createUrl('/recipes'), {params: {searchText}})).data;
        setData({recipes});
    };
    useEffect(() => {loadData()}, [searchText]);

    let initialValues, searchResults;
    if (searchText) initialValues = {searchText};

    if (data) {
        let recipePreviews = data.recipes.map(recipe => <RecipePreview recipe={recipe} history={history} key={recipe.id} />);
        if (recipePreviews.length) {
            for (let i = 0; i < 10; i++) recipePreviews.push(<RecipePreviewFiller key={`recipePreviewFiller-${i}`} />);
            searchResults = <div className="search-recipePreviews">{recipePreviews}</div>;
        } else {
            searchResults = <span className="search-noRecipesMessage">No recipes found</span>;
        }
    }
    return (
        <div className="search">
            <TitleBar title="Search" toggleSidebar={toggleSidebar} />
            <Form onSubmit={onSubmit(history)} initialValues={initialValues}>{SearchForm}</Form>
            {searchResults}
        </div>
    );
}
