import React, {useState, useEffect} from 'react';

import Alert from '../../alert/alert';
import {loadUser, loadList} from '../alert-template-services';

export default ({alert, toggleNotificationRead, closeMenu, push, userIsAdmin}) => {
    let {ownedBy, label, claimedBy, originList} = alert.event.data;
    let originListLink = '/gift-tags/lists/' + originList;

    let [ownedByUser, setOwnedByUser] = useState();
    let [claimedByUser, setClaimedByUser] = useState();
    let [list, setList] = useState();
    useEffect(() => {
        loadUser(ownedBy, setOwnedByUser);
        loadUser(claimedBy, setClaimedByUser);
        loadList(originList, setList)
    }, []);

    let message;
    if (ownedByUser && claimedByUser && list) {
        let ownerName = ownedByUser.name.fullName;
        let claimerName = claimedByUser.name.fullName;
        if (list.contributorsToAnonymize.includes(claimedBy) && !userIsAdmin) claimerName = 'Someone';
        message = <span><b>{claimerName}</b> claimed the tag of an idea for <b>{ownerName}</b> - <b>"{label}"</b></span>
    }
    return (
        <Alert
            alert={alert}
            toggleNotificationRead={toggleNotificationRead}
            link={originListLink}
            closeMenu={closeMenu}
            push={push}
        >
            {message || 'Loading Message...'}
        </Alert>
    );
}