import React from 'react';
import {Field, Form} from 'react-final-form';
import {withRouter} from 'react-router-dom';

import Hamburger from '../../../../images/react-icons/hamburger';

import './title-bar.css';

let SearchBarForm = formProps => {
    let {handleSubmit} = formProps;
    return (
        <form onSubmit={handleSubmit} className="titleBar-searchForm">
            <Field
                name="searchText"
                placeholder="Search"
                component="input"
                type="text"
                autoComplete="off"
                className="titleBar-searchBar"
            />
        </form>
    )
};

function TitleBar({title, subtitle, subtitleOnClick, isPrivate, enableSearch, toggleSidebar, icon, history}) {
    let subTitleComponent;
    if (subtitle) {
        subTitleComponent = <div className="titleBar-subTitle">{subtitle}</div>;
        if (subtitleOnClick) {
            subTitleComponent = <button className="titleBar-subTitle" onClick={subtitleOnClick}>{subtitle}</button>;
        }
    }

    let onSubmit = async values => {
        let {searchText} = values;
        if (!searchText) return;
        history.push(`/sous-chef/search?searchText=${searchText}`);
    };

    return (
        <div className="titleBar">
            <div className="titleBar-primary">
                {toggleSidebar && (
                    <button onClick={toggleSidebar} className="titleBar-hamburgerButton">
                        <Hamburger className="titleBar-hamburgerIcon"/>
                    </button>
                )}
                {icon}
                <div className="titleBar-titles">
                    <div className="titleBar-primaryTitleSection">
                        <h2>{title}</h2>
                        {isPrivate && <span>(Private)</span>}
                    </div>
                    {subTitleComponent}
                </div>
            </div>
            {enableSearch && <Form onSubmit={onSubmit}>{SearchBarForm}</Form>}
        </div>
    )
}

export default withRouter(TitleBar);
